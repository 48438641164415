import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import styles from './styles.module.scss';

const SCROLL_THRESHOLD = 80;
const SCROLL_THROTTLE_TIME = 150;
const SCROLL_TIMEOUT = 3000;

function BackToTop({
  additionalClasses = null,
  handleClick = Function.prototype,
  isHidden = true,
}) {
  const scrollRef = useRef(null);
  const threshHoldTimer = useRef(null);

  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [threshHoldScrolled, setThreshHoldScrolled] = useState(false);

  useEffect(() => {
    scrollRef.current = window.scrollY;

    const handleScroll = throttle(() => {
      const currentScrollTop = window.scrollY;
      const scrolledAmount = scrollRef.current - currentScrollTop;
      // User is scrolling down
      if (currentScrollTop > scrollRef.current) {
        setIsScrollingDown(true);
        setThreshHoldScrolled(false);
      }
      // User is scrolling up
      if (currentScrollTop < scrollRef.current) {
        setIsScrollingDown(false);
      }
      // User is scrolling up AND SCROLL_THRESHOLD distance has been reached
      if (scrolledAmount >= SCROLL_THRESHOLD) {
        setThreshHoldScrolled(true);
        clearTimeout(threshHoldTimer.current);
        threshHoldTimer.current = setTimeout(() => {
          setThreshHoldScrolled(false);
        }, SCROLL_TIMEOUT);
      }

      scrollRef.current = currentScrollTop;
    }, SCROLL_THROTTLE_TIME);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(threshHoldTimer.current);
    };
  }, []);

  const isVisible = !isScrollingDown && threshHoldScrolled && !isHidden;

  return (
    <button
      type="button"
      className={classNames(
        additionalClasses,
        styles.backToTop,
        styles.hidden,
        {
          [styles.isVisible]: isVisible,
        },
      )}
      onClick={handleClick}
      title="Back to Top"
    >
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 29.75V9.91667"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M7.08317 18.417L16.9998 8.50033L26.9165 18.417"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <rect
          x="2.8335"
          y="2.83301"
          width="28.3333"
          height="2.41667"
        />
      </svg>
    </button>
  );
}

BackToTop.propTypes = {
  additionalClasses: PropTypes.string,
  handleClick: PropTypes.func,
  isHidden: PropTypes.bool,
};

export { BackToTop };
