import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BackToTopIcon from 'components/Icon/BackToTopIcon';
import styles from './styles.module.scss';

function Pill({
  count,
  additionalClasses = null,
  handleClick = Function.prototype,
  onRef = Function.prototype,
  onAnimationEnd = Function.prototype,
  liveBlogChatRedesign = false,
}) {
  const ref = React.useRef(null);

  React.useEffect(() => {
    onRef(ref);
  }, [ref]);


  let countDisplay;
  if (count >= 10) {
    countDisplay = `9+ ${i18next.t('NEW_FEM_PLURAL')}`;
  } else if (count === 1) {
    countDisplay = `${count} ${i18next.t('NEW_FEM_SINGULAR')}`;
  } else {
    countDisplay = `${count || ''} ${i18next.t('NEW_FEM_PLURAL')}`;
  }

  const unit = count === 1
    ? i18next.t(liveBlogChatRedesign ? 'POST' : 'UPDATE')
    : i18next.t(liveBlogChatRedesign ? 'POSTS' : 'UPDATES');

  return (
    <button
      type="button"
      className={classNames(
        additionalClasses,
        styles.pill,
      )}
      onClick={handleClick}
      ref={ref}
      data-test="pill"
      data-testid="pill"
      onAnimationEnd={onAnimationEnd}
    >
      <BackToTopIcon className={styles.backToTopIcon} />
      <span className={classNames(
        'lh-title',
        styles.countLabel,
      )}
      >
        {countDisplay}
        {' '}
        {unit}
      </span>
    </button>
  );
}

Pill.propTypes = {
  additionalClasses: PropTypes.string,
  count: PropTypes.number.isRequired,
  handleClick: PropTypes.func,
  onRef: PropTypes.func,
  onAnimationEnd: PropTypes.func,
  liveBlogChatRedesign: PropTypes.bool,
};


export { Pill };
