export const listenToScroll = ({ isScrolledPastFirstWindow }, setState) => {
  const updatedIsScrolledPastFirstWindow = typeof window !== 'undefined'
      && window.pageYOffset > window.innerHeight;

  if (updatedIsScrolledPastFirstWindow !== isScrolledPastFirstWindow) {
    setState(updatedIsScrolledPastFirstWindow);
  }
};

export const monitorArticleView = (article, setState) => (id) => {
  if (id === article.id) {
    setState(true);
  }
};


/**
 * Checks if the Boxflex ad has rendered.
 *
 * @param {object} params - The parameters for the function.
 * @param {object} params.mpsAd - The MPS ad object.
 * @param {Function} params.setBoxflexRendered - The function to set the Boxflex rendered state.
 */
export const boxflexAdRenderCheck = ({
  mpsAd,
  setBoxflexRendered,
}) => {
  if (!mpsAd?.onRender) {
    console.warn('boxflexAdRenderCheck: mpsAd or mpsAd.onRender is undefined.');
    return;
  }
  mpsAd?.onRender((event) => {
    if (!event || event.empty) {
      return;
    }
    setBoxflexRendered(true);
  });
};
