import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const EYEBROW_TEXT_MAP = {
  FACT_CHECK: 'Fact Check',
  // Add more cases here as needed
};

export function ChatCardEyebrow({
  typeEnum = '',
}) {
  const eyebrowText = EYEBROW_TEXT_MAP[typeEnum];

  if (!eyebrowText) return null;

  return (
    <div className={cx(styles.chatCardEyebrow, 'df items-center ttu')}>
      {eyebrowText}
    </div>
  );
}

ChatCardEyebrow.propTypes = {
  typeEnum: PropTypes.oneOf(Object.keys(EYEBROW_TEXT_MAP)),
};

