import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './styles.themed.scss';

/**
 * Button component for loading more live blog cards.
 *
 * @param {object} props - The properties for the button component.
 * @param {boolean} [props.loadingMoreCards=false] - Flag indicating if more cards are being loaded.
 * @param {Function} [props.onClick=() => {}] - Click event handler for the button.
 * @returns {React.ReactElement} The rendered button component.
 */
const Button = ({
  loadingMoreCards = false,
  onClick = () => {},
}) => {
  const { t } = useTranslation();

  const text = t(loadingMoreCards ? 'Loading ...' : 'Load More Posts');

  return (
    <div className="df justify-center mt5 tc">
      <button
        type="button"
        onClick={onClick}
        className={classNames(
          'loadMoreButton f4 ttu z-1',
          {
            'loadMoreButton--loading': loadingMoreCards,
          },
        )}
      >
        {loadingMoreCards && <span className="icon icon-peacock mr2" />}
        {text}
      </button>
    </div>
  );
};

export default Button;
