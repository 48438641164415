import { useEffect, useState } from 'react';
import BTE from 'lib/BTE';
import { listenForHFSBump, disconnectHFSBump } from 'lib/listenForHFSBump';
import {
  listenToScroll,
  monitorArticleView,
} from '../bodyUtils';

/**
 * Custom hook to manage article scroll state.
 *
 * @param {object} params - The parameters for the hook.
 * @param {Article} params.article - The article object.
 * @param {Array} params.ads - The ads array.
 * @returns {object} - The scroll state.
 */
export function useArticleScroll({
  article = {},
  ads,
}) {
  const [isScrolledPastFirstWindow, setIsScrolledPastFirstWindow] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isInView, setIsInView] = useState(false);
  let HFSObserver = null;

  /**
 * Effect hook that sets up event listeners and subscriptions when the component mounts,
 * and cleans them up when the component unmounts.
 */
  useEffect(() => {
    /**
       * Handles the scroll event and updates the scroll state.
       */
    const handleScroll = () => {
      listenToScroll(
        { isScrolledPastFirstWindow, isScrollingUp, isInView },
        setIsScrolledPastFirstWindow,
      );
    };

    window.addEventListener('scroll', handleScroll);

    BTE.on('articleInView', monitorArticleView(article, setIsInView));

    if (ads.length) {
      HFSObserver = listenForHFSBump({ isScrollingUp }, setIsScrollingUp);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      BTE.remove('articleInView', monitorArticleView(article, setIsInView));

      if (ads.length) {
        disconnectHFSBump(HFSObserver);
      }
    };
  }, [article, isScrolledPastFirstWindow, isScrollingUp, isInView]);


  return {
    isScrolledPastFirstWindow,
    isScrollingUp,
    isInView,
  };
}
