import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const CardHeadline = ({ headline = '' }) => (
  <h2
    className={classNames(styles.cardHeadline)}
    data-test="headline"
    data-testid="headline"
  >
    {headline}
  </h2>
);

CardHeadline.propTypes = {
  headline: PropTypes.string,
};

export { CardHeadline };
