import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import classNames from 'classnames';

import scrollToElem from 'lib/scrollToElement';
import { logError } from 'lib/datadog';
import { FeatureFlagContext, RouteContext, useLiveBlogCardsContext } from 'lib/ContextTypes';
import {
  usePrevious,
} from 'lib/Hooks';

import { BlogCards } from 'components/BlogCards';
import { ErrorBoundary } from 'components/ErrorBoundary';

import { BackToTop } from './BackToTop';
import Button from './Button';
import { Pill } from './Pill';

import './styles.themed.scss';

const block = 'liveBlogCards';

export const {
  LIVE_BLOG_POLLING_INTERVAL = 30000,
} = process.env;

/**
 * Component to render live blog posts.
 *
 * @param {object} props - The properties for the live blog posts
 * @param {string} props.articleCanonicalUrl - The canonical URL of the article.
 * @param {string} [props.backToTopBtnsAlignDesktop='top'] - Alignment of back to top buttons on desktop.
 * @param {string} [props.backToTopBtnsAlignMobile='top'] - Alignment of back to top buttons on mobile.
 */
export function LiveBlogPosts({
  articleCanonicalUrl,
  backToTopBtnsAlignDesktop = 'top',
  backToTopBtnsAlignMobile = 'top',
}) {
  const { path } = useContext(RouteContext);

  const [isScrollingToTop, setIsScrollingToTop] = useState(false);
  const [isScrolledPastFirstCard, setIsScrolledPastFirstCard] = useState(false);
  const [shouldPulseNewPosts, setShouldPulseNewPosts] = useState(false);

  const liveBlogContainerRef = useRef();

  const { liveBlogChatRedesign } = React.useContext(FeatureFlagContext);

  const {
    activeItems,
    isFetchingMoreCardsToLoad,
    isMoreCardsAvailable,
    makeLatestActive,
    countNewItems,
    loadMoreCards,
  } = useLiveBlogCardsContext();

  const prevCountNewItems = usePrevious(countNewItems);

  useEffect(() => {
    if ((countNewItems !== prevCountNewItems) && countNewItems >= 2) {
      setShouldPulseNewPosts(true);
    }
  }, [countNewItems]);

  const scrollToTop = useCallback((cb = () => { }) => {
    if (
      typeof window === 'undefined'
      || isScrollingToTop
      || !liveBlogContainerRef.current
      || !liveBlogContainerRef.current.getBoundingClientRect
    ) {
      return null;
    }

    setIsScrollingToTop(true);

    const absoluteScrollPos = liveBlogContainerRef.current.getBoundingClientRect().top
      + window.pageYOffset
      - 170;

    return scrollToElem(absoluteScrollPos, 1000, 'easeInOut', () => {
      setIsScrollingToTop(false);

      if (typeof cb === 'function') cb();
    });
  }, [isScrollingToTop]);

  const scrollToTopAndUpdateCards = useCallback(() => {
    scrollToTop(() => {
      makeLatestActive();
    });
  }, [scrollToTop, makeLatestActive]);

  const firstCardInViewListener = useCallback(([intObsrvEntry]) => {
    const isScrolledPastFirstCardThreshold = intObsrvEntry.intersectionRatio >= 0.5;
    setIsScrolledPastFirstCard(isScrolledPastFirstCardThreshold);
  }, []);

  const showNewPostsButton = (
    <ErrorBoundary errorLogger={logError}>
      <Pill
        additionalClasses={classNames(
          `${block}__button`,
          `${block}__posts-button`,
          {
            [`${block}__posts-button--hidden`]: !countNewItems,
            [`${block}__button--hidden`]: !countNewItems,
            [`${block}__posts-button--pulse`]: shouldPulseNewPosts,
            [`${block}__posts-button--chat-style-redesign`]: liveBlogChatRedesign,
          },
        )}
        count={countNewItems || prevCountNewItems}
        handleClick={scrollToTopAndUpdateCards}
        onAnimationEnd={() => setShouldPulseNewPosts(false)}
        liveBlogChatRedesign={liveBlogChatRedesign}
      />
    </ErrorBoundary>
  );

  const isBackToTopHidden = countNewItems || isScrollingToTop || !isScrolledPastFirstCard;

  const backToTopButton = (
    <ErrorBoundary errorLogger={logError}>
      <BackToTop
        additionalClasses={`${block}__button`}
        handleClick={scrollToTop}
        isHidden={isBackToTopHidden}
      />
    </ErrorBoundary>
  );

  const bttBtnsBaseClassName = `${block}__scrollUpButtonContainer`;
  const backToTopBtns = activeItems.length > 0 && (
    <div className={classNames(
      bttBtnsBaseClassName,
      {
        [`${bttBtnsBaseClassName}--desktopTop`]: backToTopBtnsAlignDesktop === 'top',
        [`${bttBtnsBaseClassName}--desktopBottom`]: backToTopBtnsAlignDesktop === 'bottom',
        [`${bttBtnsBaseClassName}--mobileTop`]: backToTopBtnsAlignMobile === 'top',
        [`${bttBtnsBaseClassName}--mobileBottom`]: backToTopBtnsAlignMobile === 'bottom',
      },
    )}
    >
      {showNewPostsButton}
      {backToTopButton}
    </div>
  );

  const loadMoreButton = (
    <ErrorBoundary errorLogger={logError}>
      <Button onClick={() => loadMoreCards()} loadingMoreCards={isFetchingMoreCardsToLoad} />
    </ErrorBoundary>
  );

  return (
    <div
      className={classNames('LiveBlog relative', block)}
      id="liveBlogCards"
      ref={liveBlogContainerRef}
      data-testid="live-blog-posts"
    >
      <div className="cardsContainer">
        <BlogCards
          activeItems={activeItems}
          onFirstCardInView={firstCardInViewListener}
          parentArticleCanonicalUrl={articleCanonicalUrl}
          path={path}
        />
      </div>
      {backToTopBtns}
      {isMoreCardsAvailable && loadMoreButton}
    </div>
  );
}
