import React, {
  useContext, useState, useEffect, memo,
} from 'react';
import classNames from 'classnames';
import { liveBlogCard } from 'lib/CustomPropTypes';
import { LiveBlogContext, VerticalContext } from 'lib/ContextTypes';
import InlineVideo from 'components/InlineVideo';
import { cardMarkup } from 'components/Card/CardUtils';
import { SocialShareIconList } from 'components/Social/SocialShareIconList';
import { ChatCardHeadline } from './ChatCardHeadline/index';
import { ChatCardTimeStamp } from './ChatCardTimestamp/index';
import { ChatCardByline } from './ChatCardByline/index';
import { ChatCardBylineThumbnail } from './ChatCardBylineThumbnail/index';
import { ChatCardAuthorCount } from './ChatCardAuthorCount/index';
import { ChatCardBylineNames } from './ChatCardBylineNames/index';
import { ChatCardReportingFrom } from './ChatCardReportingFrom/index';
import { ChatCardEyebrow } from './ChatCardEyebrow/index';

import styles from './styles.module.scss';
import { ChatCardFactCheck } from './ChatCardFactCheck/index';


const hiddenStyles = {
  display: 'inline-block',
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
};

/**
 * Chat style live blog card component
 * @param {object} props
 * @param {string} [props.cardType]
 * @param {Author[]} [props.authors]
 * @param {MultiFormatContent & {
 *  visibleMarkupAndEmbeds: MultiFormatContent[],
 *  hiddenMarkupAndEmbeds: MultiFormatContent[],
 *  hasShowMoreBreakpoint: boolean,
 * }} props.content
 * @param {string} props.date
 * @param {Headline} [props.headline]
 * @param {boolean} props.isHeadlineHidden
 * @param {string} props.id
 * @param {string} props.path
 * @param {Video} [props.cardData]
 * @param {string} props.socialUrl
 * @param {string} [props.className]
 * @param {string} [props.parentStyle]
 * @param {string} props.reportingFrom
 * @param {FactCheckContent} [props.factCheckContent]
 * @param {Presentation} [props.presentation]
 * @returns {React.ReactElement}
 */
const ChatCard = ({
  cardType,
  authors = [],
  content: {
    /**
     * markupAndEmbeds should be data from the API, and should be respected if
     * `visibleMarkupAndEmbeds` isn't passed in
     */
    markupAndEmbeds = [],
    visibleMarkupAndEmbeds,
    hiddenMarkupAndEmbeds,
    hasShowMoreBreakpoint,
  },
  date,
  headline: { primary: primaryHeadline },
  isHeadlineHidden = false,
  id,
  path,
  cardData: videoData,
  socialUrl,
  className,
  parentStyle,
  reportingFrom = null,
  factCheckContent = null,
  presentation,
}) => {
  const { isLive } = useContext(LiveBlogContext);
  const vertical = useContext(VerticalContext);
  const [thumbnailsTapped, setThumbnailsTapped] = useState(false);
  const thumbnailRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const overflowContentRef = React.useRef(null);

  const [showHiddenMarkupAndEmbeds, setShowHiddenMarkupAndEmbeds] = React.useState(false);
  const cardClasses = classNames(
    styles['chat-blog-card'],
    className === 'isReply' && styles.isReply,
    'article-body__content',
    'w-100 mh0 fn',
    parentStyle,
  );

  useEffect(() => {
    /**
     * @param {React.TouchEvent} e
     */
    const handleTouchEnd = (e) => {
      if (!e.composedPath().includes(thumbnailRef.current)
        && !e.composedPath().includes(dropdownRef.current)) {
        setThumbnailsTapped(false);
      }
    };

    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);


  useEffect(() => {
    if (showHiddenMarkupAndEmbeds && overflowContentRef.current) {
      overflowContentRef.current.focus();
    }
  }, [showHiddenMarkupAndEmbeds]);

  const totalAuthorCount = authors?.length ?? 0;

  const isSingleByline = totalAuthorCount === 1;
  const isMultiBylines = totalAuthorCount > 1;

  const icid = 'ed_npd_nn_nn_np_liveblog';
  const dataTrackCardUrlIcid = 'live-blog-card-link';

  /**
   * if `visibleMarkupAndEmbeds` was not generated and passed in, but markupAndEmbeds exists, then
   * we should use that array instead, which should be data coming from the API directly
   */
  const cardBodyItems = visibleMarkupAndEmbeds || markupAndEmbeds;

  return (
    <div
      className={cardClasses}
      key={id}
      id={id}
      data-testid="chat-card-container"
    >
      <ChatCardEyebrow typeEnum={presentation?.style} />
      <div className={
        classNames(
          styles['time-stamp-social-share-container'],
          className === 'isReply' ? 'pb1' : 'pv1',
        )
      }
      >
        {date && (
          <ChatCardTimeStamp date={date} isLive={isLive} />
        )}
        {className !== 'isReply' && (
          <SocialShareIconList
            vertical={vertical}
            primaryHeadline={primaryHeadline}
            socialUrl={socialUrl}
            icid={icid}
            id={id}
          />
        )}
      </div>

      {!isHeadlineHidden && primaryHeadline && (
        <>
          <ChatCardHeadline headline={primaryHeadline} />
        </>
      )}

      {Array.isArray(authors) && totalAuthorCount > 0 && (
        <div
          className={classNames(styles['authors-container'], reportingFrom ? 'mb2' : 'mb3')}
          data-testid="chat-card-authors-container"
        >
          {isSingleByline && (
            <ChatCardByline
              key={`byline-${authors[0]?.id}`}
              author={authors[0]}
              position={1}
            />
          )}
          {isMultiBylines && (
            <>
              <div
                className={classNames(styles['author-thumbnails'], { [styles.isTapped]: thumbnailsTapped })}
                onTouchEnd={(e) => {
                  if (!e.nativeEvent.composedPath().includes(dropdownRef.current)) {
                    setThumbnailsTapped(!thumbnailsTapped);
                  }
                }}
                ref={thumbnailRef}
                data-testid="author-thumbnails"
              >
                {authors.map((author, index) => (
                  <React.Fragment key={author.id}>
                    {/* Biz logic: Display 2 author thumbnails when there are 2 authors else only show 1 */}
                    {totalAuthorCount === 2
                      ? (
                        <ChatCardBylineThumbnail
                          key={`bylinethumbnail-${author.id}`}
                          author={author}
                          position={index + 1}
                        />
                      )
                      : (index === 0
                        && (
                          <ChatCardBylineThumbnail
                            key={`bylinethumbnail-${author.id}`}
                            author={author}
                            position={index + 1}
                          />
                        )
                      )}
                  </React.Fragment>
                ))}
                {totalAuthorCount > 2 && (
                  <>
                    <ChatCardAuthorCount count={totalAuthorCount - 1} />
                  </>
                )}
                <div className={styles['author-dropdown']} ref={dropdownRef} data-testid="author-dropdown">
                  {authors.map((author, index) => (
                    <ChatCardByline
                      key={`dropdown-${author.id}`}
                      author={author}
                      position={index + 1}
                      classNames={styles['dropdown-item']}
                    />
                  ))}
                </div>
              </div>
              <div className="authors-names">
                {authors.map((author, index) => (
                  <ChatCardBylineNames
                    key={`bylinenames-${author.id}`}
                    author={author}
                    index={index}
                    totalAuthorCount={totalAuthorCount}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {reportingFrom && (
        <ChatCardReportingFrom text={reportingFrom} className="mb3" />
      )}
      <div
        data-icid={dataTrackCardUrlIcid}
        data-testid="chat-card-body-container"
      >
        {cardMarkup({
          bodyItems: cardBodyItems,
          path,
          showHiddenMarkupAndEmbeds,
          setShowHiddenMarkupAndEmbeds,
          id,
          headline: primaryHeadline,
        })}

        {(factCheckContent && !hasShowMoreBreakpoint)
          && <ChatCardFactCheck id={id} factCheckContent={factCheckContent} />}
        {showHiddenMarkupAndEmbeds
          ? (
            <>
              {/* H3 is because CSS for Article/Cards relies on direct descendant and sibling selectors, adding wrapping markup would break the styles. */}
              <h3 tabIndex="-1" ref={overflowContentRef} style={hiddenStyles}>
                {primaryHeadline}
                , continued
              </h3>
              {cardMarkup({
                bodyItems: hiddenMarkupAndEmbeds, path, id, headline: primaryHeadline,
              })}
              <ChatCardFactCheck id={id} factCheckContent={factCheckContent} />
            </>
          )
          : (
            <div id={`${id}-overflow-content`} className={classNames(styles['hidden-show-more-content'], { dn: !hiddenMarkupAndEmbeds?.length })}>
              {cardMarkup({
                bodyItems: hiddenMarkupAndEmbeds, path, id, headline: primaryHeadline,
              })}
              <ChatCardFactCheck id={id} factCheckContent={factCheckContent} />
            </div>
          )}

        {cardType === 'VIDEO' && <InlineVideo video={videoData} />}
      </div>
    </div>
  );
};

ChatCard.propTypes = {
  ...liveBlogCard,
};

const memoizedChatCard = memo(ChatCard);

export { memoizedChatCard as ChatCard };
