import React from 'react';
import PropTypes from 'prop-types';
import { author as AuthorPropType } from 'lib/CustomPropTypes';
import { BylineThumbnail } from 'components/Byline';

import styles from './styles.module.scss';

export function ChatCardBylineThumbnail({
  position,
  isLinked = false,
  key = null,
  author = null,
}) {
  if (!author) return null;
  return (
    <div className={styles.chatCardBylineThumbnail} data-testid="chat-card-byline-thumbnail">
      <BylineThumbnail
        key={key}
        person={author}
        position={position}
        chatStyle
        additionalClasses={styles.bylineThumbnail}
        isLinked={isLinked}
      />
    </div>
  );
}

ChatCardBylineThumbnail.propTypes = {
  author: AuthorPropType,
  position: PropTypes.number.isRequired,
  isLinked: PropTypes.bool,
  key: PropTypes.string,
};

