import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getCloudinaryImageSrc } from 'lib/imageUtils';

import { LinkIfHref } from 'components/Link';

import styles from './styles.module.scss';

/**
 * @type {React.FunctionComponent}
 * @param {object} props The props of the component.
 * @param {object} props.logo.url The logo object, which includes the src and alt text of the logo.
 * @param {string} props.logo.altText The alt text of the logo.
 * @param {string} props.link The URL of the link that wraps the logo.
 * @param {string} props.fallbackAlt Fallback alt text string for img
 * @returns {React.Element} A React element that represents the TopicLogo component.
 */

export function TopicLogo({
  logo = undefined,
  link = undefined,
  fallbackAlt = 'Related Topic Logo',
  height = 21,
}) {
  if (!logo) return null;

  return (
    <LinkIfHref
      className={cx(styles.logoLink, 'db')}
      href={link}
      data-icid="topic_lb_subnav"
    >
      <img className={cx(styles.logo, 'db')} src={getCloudinaryImageSrc(logo.url?.primary, 'fit-260w', 'best', 2)} alt={logo.altText ? logo.altText : fallbackAlt} height={height} />
    </LinkIfHref>
  );
}

TopicLogo.propTypes = {
  logo: PropTypes.shape({
    url: PropTypes.shape({
      primary: PropTypes.string,
    }),
    altText: PropTypes.string,
  }),
  link: PropTypes.string,
  fallbackAlt: PropTypes.string,
  height: PropTypes.number,
};
