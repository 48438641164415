import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FeatureFlagContext } from 'lib/ContextTypes';
import { Item } from 'components/MsnbcDaily/Recirc/End/Item';
import { RunExperiment } from 'components/RunExperiment';
import { getDataActivityMapID } from 'lib/articleUtils';
import styles from './styles.module.scss';

const MostPopularStoryList = ({ mostPopularStoryListItems, vertical }) => {
  const { 'is-most-popular-module-enabled': isMostPopularModuleEnabled } = useContext(FeatureFlagContext);

  const mostPopularHeadlineByVertical = { news: 'Most popular on NBC News', msnbc: '', today: '' };
  const headline = mostPopularHeadlineByVertical[vertical];

  if (!isMostPopularModuleEnabled || !Number.isFinite(mostPopularStoryListItems?.length) || mostPopularStoryListItems?.length < 3) {
    return null;
  }

  const dataActivityMapID = getDataActivityMapID({
    pageRegion: 'article-body',
    componentName: 'most-popular-story-list',
    componentTitle: 'most-popular-story-list',
  });

  const renderStoryList = () => (
    <div data-testid="most-popular-list" className={styles.outerContainer} data-activity-map={dataActivityMapID}>
      <div className={styles.mostPopularList}>
        <h3>{ headline }</h3>
        <div className={styles.itemContainer}>
          { mostPopularStoryListItems?.slice(0, 6).map((mostPopularStoryItem, index) => (
            <div className={styles.popularItem}>
              <Item
                headline={mostPopularStoryItem.headline?.tease}
                index={index + 1}
                url={mostPopularStoryItem.url?.primary}
                icid="mostpopular"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <RunExperiment variantComponents={[renderStoryList()]} campaignNumber={530}>
      <span data-testid="most-popular-list" />
    </RunExperiment>
  );
};

MostPopularStoryList.propTypes = {
  mostPopularStoryListItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  vertical: PropTypes.string.isRequired,
};

export default MostPopularStoryList;
