import React from 'react';
import PropTypes from 'prop-types';
import { author as AuthorPropType } from 'lib/CustomPropTypes';

import { ChatCardBylineName } from 'components/ChatCard/ChatCardBylineName';

export function ChatCardBylineNames({
  index,
  author = null,
  totalAuthorCount = null,
}) {
  if (author === null || totalAuthorCount === null) return null;
  return (
    <>
      <ChatCardBylineName
        key={`byline-names-name${author.id}`}
        person={author}
        position={index + 1}
        chatStyle
        showShortBio={totalAuthorCount === 1 && index === 0}
      />
      {/* Adding a comma (,) after each author */}
      {(index !== totalAuthorCount - 2 && index !== totalAuthorCount - 1) && <>, </>}
      {/* Adding an "and" before the last author */}
      {index === totalAuthorCount - 2 && <> and </>}
    </>
  );
}

ChatCardBylineNames.propTypes = {
  author: AuthorPropType,
  index: PropTypes.number.isRequired,
  totalAuthorCount: PropTypes.number,
};

