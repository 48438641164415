import React from 'react';
import PropTypes from 'prop-types';

import { TopicTitle } from './TopicTitle';
import { TopicLink } from './TopicLink';
import { TopicLogo } from './TopicLogo';

import styles from './styles.module.scss';

/**
 * @typedef {object} LinkItem
 * @property {string} id
 * @property {object} headline
 * @property {string} text
 * @property {string} headline.primary
 * @property {object} url
 * @property {string} url.primary
 * @property {string} subType
 * @property {string} flag
 */

/**
 * @type {React.FunctionComponent}
 * @param {object} props
 * @param {string} [props.titleText]
 * @param {string} [props.titleLink]
 * @param {object} [props.logo]
 * @param {LinkItem[]} [props.linkItems]
 * @param {boolean|(item: LinkItem) => boolean} [props.isActiveLink]
 */
export function TopicSubNav({
  titleText = undefined,
  titleLink = undefined,
  linkItems = undefined,
  isActiveLink = undefined,
  logo = undefined,
}) {
  const linkItemsMarkup = linkItems?.map?.((item) => {
    if (!item?.headline) {
      if (!item?.text) return null;
    }
    const isLive = item.subType === 'liveBlog' && item.flag === 'LIVE COVERAGE';

    const activeLink = typeof isActiveLink === 'function'
      ? isActiveLink(item)
      : isActiveLink ?? false;

    return (
      <TopicLink
        key={item?.id}
        text={item?.headline?.primary || item?.text}
        url={item?.url?.primary}
        isActiveLink={activeLink}
        isHighlighted={isLive}
      />
    );
  });

  return (
    <nav className={styles.topicSubNav} data-testid="topic-sub-nav">
      <div
        className={styles.topicSubNavInner}
        data-testid="topic-sub-nav-inner"
      >
        <div
          className={styles.scrollingContent}
          data-testid="subnav-scrolling-content"
        >
          {logo ? (
            <TopicLogo logo={logo} link={titleLink} fallbackAlt={titleText} />
          ) : (
            <TopicTitle text={titleText} url={titleLink} />
          )}
          <ul className={styles.linkItems}>
            {linkItemsMarkup}
          </ul>
        </div>
      </div>
    </nav>
  );
}

TopicSubNav.propTypes = {
  titleText: PropTypes.string,
  titleLink: PropTypes.string,
  linkItems: PropTypes.arrayOf(PropTypes.shape({
    headline: PropTypes.shape({
      primary: PropTypes.string,
    }),
    id: PropTypes.string,
    url: PropTypes.shape({
      primary: PropTypes.string,
    }),
  })),
  isActiveLink: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  logo: PropTypes.shape({
    url: PropTypes.shape({
      primary: PropTypes.string,
    }),
    altText: PropTypes.string,
  }),
};

