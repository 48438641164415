import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import Ad from 'components/Ad';
import { Card } from 'components/Card';
import { ChatCard } from 'components/ChatCard';
import WindowTopObserver from 'components/IntObserver/WindowTopObserver';
import { ErrorBoundary } from 'components/ErrorBoundary';

import { FeatureFlagContext } from 'lib/ContextTypes';
import { LiveBlogAdController } from 'lib/AdControllers';
import { liveBlogCard } from 'lib/CustomPropTypes';

import { getShowMoreBifurcatedMarkupEmbeds } from 'lib/blogUtils';
import { modifyIfUrl, stripTrailingSlash } from 'lib/urlUtils';
import { logError } from 'lib/datadog';
import styles from './styles.module.scss';

/**
 * LiveBlogCard component to display a live blog card.
 *
 * @param {object} props - The properties object.
 * @param {object} props.card - The card data.
 * @param {boolean} props.liveBlogChatRedesign - Indicates if the live blog chat redesign is enabled.
 * @param {number} props.index - The index of the card.
 * @param {boolean} props.isLastCard - Indicates if this is the last card.
 * @param {string} props.parentArticleCanonicalUrl - The canonical URL of the parent article.
 * @param {string} props.path - The path of the card.
 * @param {Function} props.onFirstCardInView - Callback function when the first card is in view.
 */
const LiveBlogCard = ({
  card,
  liveBlogChatRedesign,
  index,
  isLastCard,
  parentArticleCanonicalUrl,
  path,
  onFirstCardInView,
}) => {
  const newContent = useMemo(() => {
    if (card.type === 'ad') {
      return null;
    }

    return getShowMoreBifurcatedMarkupEmbeds(card);
  }, [card]);

  if (card.type === 'ad') {
    return <Ad key={card.id} {...card} adClass="mb8" />;
  }

  const hasReplies = Boolean(card.replies?.length);

  const socialUrl = modifyIfUrl(parentArticleCanonicalUrl, (parsedUrl) => {
    /* eslint-disable no-param-reassign */
    parsedUrl.pathname = `${stripTrailingSlash(parsedUrl.pathname)}/${card.id}`;
    parsedUrl.hash = '';
    parsedUrl.searchParams.set('canonicalCard', 'true');
    /* eslint-enable no-param-reassign */
  });

  const cardComponent = (
    <ErrorBoundary errorLogger={logError}>
      {liveBlogChatRedesign ? (
        <>
          <ChatCard
            /* eslint-disable react/jsx-props-no-spreading */
            {...card}
            content={newContent}
            isLastCard={isLastCard}
            path={path}
            key={card.id}
            socialUrl={socialUrl}
          />

          {hasReplies && (
            <div>
              <div className={styles.repliesCount}>
                {`${card.replies.length} ${card.replies.length === 1 ? 'reply' : 'replies'}`}
              </div>
              <div className={styles.replies}>
                {card.replies.map((reply) => (
                  <ChatCard
                    {...reply}
                    content={reply.content}
                    isLastCard={isLastCard}
                    path={path}
                    key={reply.id}
                    socialUrl={socialUrl}
                    isHeadlineHidden
                    isShareToolsHidden
                    className="isReply"
                    parentStyle={styles.hasParent}
                  />
                ))}
              </div>
            </div>

          )}
        </>
      ) : (
        <Card
          /* eslint-disable react/jsx-props-no-spreading */
          {...card}
          content={newContent}
          isLastCard={isLastCard}
          path={path}
          key={card.id}
          socialUrl={socialUrl}
        />
      )}
    </ErrorBoundary>
  );

  return index === 0
    ? (
      <WindowTopObserver
        key={`${card.id}-window-top-observer`}
        threshold={0.5}
        callback={onFirstCardInView}
      >
        {cardComponent}
      </WindowTopObserver>
    )
    : cardComponent;
};

LiveBlogCard.propTypes = {
  card: PropTypes.shape(liveBlogCard).isRequired,
  index: PropTypes.number.isRequired,
  isLastCard: PropTypes.bool.isRequired,
  liveBlogChatRedesign: PropTypes.bool.isRequired,
  parentArticleCanonicalUrl: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onFirstCardInView: PropTypes.func.isRequired,
};

/**
 *
 * @type {React.FunctionComponent}
 * @param {object} props
 * @param {Card[]} props.activeItems
 * @param {Function} [props.onFirstCardInView] callback passed to WindowTopObserver on first card
 * @param {string} [props.parentArticleCanonicalUrl] canonical URL of the parent (live/non-live)
 * blog to which these cards belong
 * @param {string} [props.path] path of the current page/URL/route
 * @param {number} [props.trueTotalItems]
 * @returns {JSX.Element[]}
 */
export function BlogCards(props) {
  const {
    path,
    activeItems = [],
    trueTotalItems,
    parentArticleCanonicalUrl,
    onFirstCardInView = () => {},
  } = props;

  const { 'live-blog-chat-redesign': liveBlogChatRedesign } = useContext(FeatureFlagContext);
  const cardsWithAds = LiveBlogAdController.insertBoxinlineAds(activeItems);
  let cardCount = 0;

  return cardsWithAds.map((card, i) => {
    // Store count of rendered cards
    cardCount += 1;
    // Rendered cards equals true count of cards
    const isLastCard = cardCount === trueTotalItems;

    return (
      <LiveBlogCard
        card={card}
        isLastCard={isLastCard}
        path={path}
        liveBlogChatRedesign={liveBlogChatRedesign}
        parentArticleCanonicalUrl={parentArticleCanonicalUrl}
        onFirstCardInView={onFirstCardInView}
        key={card.id || card.key}
        index={i}
      />
    );
  });
}

BlogCards.propTypes = {
  activeItems: PropTypes.arrayOf(PropTypes.shape({})),
  onFirstCardInView: PropTypes.func,
  parentArticleCanonicalUrl: PropTypes.string,
  path: PropTypes.string,
  trueTotalItems: PropTypes.number,
};
