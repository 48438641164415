import React from 'react';
import { isShellArticle } from 'lib/article';

/**
 * SocialAndSponsored component to render social and sponsored content.
 *
 * @param {object} props - The properties object.
 * @param {string} props.bodyClasses - The CSS classes for the body.
 * @param {object} [props.nativeAd] - The native ad object.
 * @param {object} [props.source] - The source object.
 * @param {Article} props.article - The article object.
 * @returns {JSX.Element|null} The SocialAndSponsored component or null if the article is a shell article.
 */
export const SocialAndSponsored = ({
  bodyClasses,
  nativeAd,
  source,
  article,
}) => {
  if (isShellArticle(article)) {
    return null;
  }

  return (
    <div className="article-body__section">
      <div className={bodyClasses}>
        {nativeAd && source?.copyright ? (
          <p className="article-body__sponsored-by-text">{source.copyright}</p>
        ) : null}
      </div>
    </div>
  );
};

