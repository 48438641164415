import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { unibrow as UnibrowPropType } from 'lib/CustomPropTypes';

import Link from 'components/Link';
import { Count } from 'components/MsnbcDaily/Count';
import { Unibrow } from 'components/Unibrow';

import styles from './styles.module.scss';

function Item({
  headline,
  index,
  unibrow,
  url,
  active = false,
  publicationName = null,
  type = null,
  icid = null,
}) {
  const isCustomLink = type === 'custom';
  const target = isCustomLink ? '_blank' : '_top';

  return (
    <>
      <div>
        <Count active={active}>{index}</Count>
      </div>
      <div className={styles.info}>
        {active && (
          <div data-testid="active" className={styles.active}>
            Currently Reading
          </div>
        )}
        <Unibrow
          className={classNames(styles.unibrow, { [styles.isActive]: active })}
          size="h4"
          target={target}
          unibrow={unibrow}
          icid={icid}
        />
        <Link
          className={styles.title}
          target={target}
          to={url}
          icid={icid}
        >
          {headline}
        </Link>
        {isCustomLink && (
          <Link
            className={styles.externalLink}
            target={target}
            to={url}
            icid={icid}
          >
            {publicationName}
            <span className="icon icon-external-link-arrow" />
          </Link>
        )}
      </div>
    </>
  );
}

Item.propTypes = {
  active: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  publicationName: PropTypes.string,
  type: PropTypes.string,
  unibrow: UnibrowPropType.isRequired,
  url: PropTypes.string.isRequired,
  icid: PropTypes.string,
};


export { Item };
