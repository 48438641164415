import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'components/Accordion';
import { JumpLinkList } from 'components/JumpLinkList';
import classNames from 'classnames';
import styles from './styles.module.scss';

/**
 * Renders a list of jump links to content within the page.
 *
 * @param {object} props
 * @param {boolean} props.isCollapsible
 * @param {Array} [props.links]
 * @param {string} props.id
 * @param {string} props.testId
 * @param {string} props.title
 * @param {number} props.topOffset
 * @param {boolean} props.visible
 * @param {string} [props.lastInteraction]
 * @returns {React.ReactElement|null} - The rendered component or null.
 */
const TableOfContents = ({
  isCollapsible = true,
  links = [],
  id = 'table-of-contents',
  testId = 'table-of-contents',
  title = '',
  topOffset = '60px',
  visible = true,
  lastInteraction = undefined,
}) => {
  const componentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(!isCollapsible);
  const [wasFocusedViaKeyboard, setWasFocusedViaKeyboard] = useState(false);

  /**
   * Toggles the accordion open and close.
   */
  const toggleAccordion = () => {
    if (isCollapsible) {
      setIsOpen((prev) => !prev);
    }
  };

  /**
   * Prevents the table of contents from hiding after it has been focused via keyboard.
   */
  const onAccordionFocus = () => {
    if (lastInteraction === 'keydown') {
      setWasFocusedViaKeyboard(true);
    }
  };

  if (!title || !links.length) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.tableOfContents,
        { [styles.hidden]: !visible && !wasFocusedViaKeyboard },
      )}
      data-testid={testId}
      ref={componentRef}
      role="button"
      style={{ top: topOffset }}
      tabIndex="-1"
      id={id}
    >
      <Accordion
        isCollapsible={isCollapsible}
        isOpen={isOpen}
        onToggle={toggleAccordion}
        onFocus={onAccordionFocus}
        title={title}
      >
        <JumpLinkList
          callback={toggleAccordion}
          clickTrackingId="mbt_tableofcontents_jumplink"
          links={links}
        />
      </Accordion>
    </div>
  );
};

TableOfContents.propTypes = {
  isCollapsible: PropTypes.bool,
  id: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
  topOffset: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    linkText: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
  })),
  visible: PropTypes.bool,
  lastInteraction: PropTypes.string,
};


export {
  TableOfContents,
};
