import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { markupAndEmbeds } from 'lib/CustomPropTypes';

import { CheckStep } from './CheckStep';
import { Citations } from './Citations';

import styles from './styles.module.scss';

/**
 * ChatCardFactCheck renders a fact check with Verdict, Analysis, and Citations sections.
 * Each section is rendered using the CheckStep component.
 *
 * @component
 * @param {object} props - The properties that define the ChatCardFactCheck component.
 * @param {string} props.classNames - Additional CSS classes to apply to the component. Defaults to an empty string.
 * @param {object} props.factCheckContent - The content for the fact check card. It should have properties 'verdict', 'analysis', and 'citations', each of which should be an object with a 'markupAndEmbeds' property that is an array of objects following the shape defined in the markupAndEmbeds custom prop type. Defaults to null.
 *
 * @example
 * <ChatCardFactCheck classNames="custom-class" factCheckContent={factCheckContent} />
 *
 * @returns {React.Element} The rendered ChatCardFactCheck component, or null if factCheckContent is null.
 */

export function ChatCardFactCheck({
  id = '',
  classNames = '',
  factCheckContent = null,
}) {
  if (!factCheckContent) return null;

  const {
    verdict,
    analysis,
    citations,
  } = factCheckContent;

  const hasVerdict = Boolean(verdict?.markupAndEmbeds?.length);
  const hasAnalysis = Boolean(analysis?.markupAndEmbeds?.length);
  const hasCitations = Boolean(citations?.markupAndEmbeds?.length);

  if (!hasVerdict && !hasAnalysis && !hasCitations) return null;

  return (
    <div className={cx(styles.factCheck, classNames)} data-testid="chat-card-fact-check">
      {/* Biz Logic: Steps should always render in the following order: Verdict, Analysis, & Citations */}
      {hasVerdict && (
        <CheckStep heading="Verdict" bodyItems={verdict.markupAndEmbeds} />
      )}
      {hasAnalysis && (
        <CheckStep heading="Analysis" bodyItems={analysis.markupAndEmbeds} />
      )}
      {hasCitations && (
        <Citations id={id} bodyItems={citations.markupAndEmbeds} />
      )}
    </div>
  );
}

ChatCardFactCheck.propTypes = {
  id: PropTypes.string,
  classNames: PropTypes.string,
  factCheckContent: PropTypes.shape({
    analysis: markupAndEmbeds,
    citations: markupAndEmbeds,
    verdict: markupAndEmbeds,
  }),
};

