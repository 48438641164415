import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LinkIfHref } from 'components/Link';

import styles from './styles.module.scss';

/**
 * @type {React.FunctionComponent}
 * @param {object} props
 * @param {string} [props.text]
 * @param {string} [props.url]
 * @param {boolean} [props.isUnderlined=false]
 * @param {boolean} [props.isHighlighted=false]
 */
export function TopicLink({
  text = undefined,
  url = undefined,
  isActiveLink = false,
  isHighlighted = false,
}) {
  if (!text) return null;

  return (
    <li
      className={classNames(styles.linkItem, {
        [styles.underline]: isActiveLink,
        [styles.highlighted]: isHighlighted,
      })}
      data-testid="topic-link-li"
    >
      <LinkIfHref
        className={styles.link}
        href={isActiveLink ? null : url}
        data-icid="topic_lb_subnav"
      >
        <span
          className={styles.link}
          data-testid="topic-link-span"
        >
          {text}
        </span>
      </LinkIfHref>
    </li>
  );
}

TopicLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  isActiveLink: PropTypes.bool,
  isHighlighted: PropTypes.bool,
};

