import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';

import { article as ArticlePropType } from 'lib/CustomPropTypes';

import { ExpandedByline } from './byline';
import { Contributors } from './contributors';

import './styles.themed.scss';

const block = 'expanded-byline-contributors';

export const ExpandedBylineContributors = ({
  article = {},
  additionalClasses = '',
  contributorsAdditionalClasses = '',
  bylineAdditionalClasses = '',
}) => {
  const {
    presentation: {
      byline,
    },
  } = article;
  const articleAuthors = get(article, 'authors') ?? [];

  const authors = articleAuthors.filter((author) => author.authorType === 'author');
  const contribs = articleAuthors.filter((author) => author.authorType === 'contributor');

  const hasByline = byline
      && byline.toLowerCase() === 'expanded'
      && Array.isArray(authors)
      && !!authors.length;

  const hasContributors = Array.isArray(contribs) && !!contribs.length;

  if (!hasByline && !hasContributors) {
    return null;
  }

  return (
    <div className={classNames(block, additionalClasses)}>
      {hasByline
          && authors.map((author, i) => (
            <ExpandedByline
              key={author.person.name}
              author={author}
              containerClass={bylineAdditionalClasses}
              position={i + 1}
            />
          ))}
      {hasContributors && (
        <Contributors
          authors={contribs}
          containerClass={contributorsAdditionalClasses}
        />
      )}
    </div>
  );
};


ExpandedBylineContributors.propTypes = {
  article: ArticlePropType,
  additionalClasses: PropTypes.string,
  bylineAdditionalClasses: PropTypes.string,
  contributorsAdditionalClasses: PropTypes.string,
};
