import React from 'react';
import PropTypes from 'prop-types';

import { LinkIfHref } from 'components/Link';

import styles from './styles.module.scss';

/**
 * @type {React.FunctionComponent}
 */
export function TopicTitle({
  text = undefined,
  url = undefined,
}) {
  if (!text) return null;

  return (
    <LinkIfHref
      href={url}
      className={styles.titleLink}
      data-icid="topic_lb_subnav"
    >
      <h3 className={styles.title}>{text}</h3>
    </LinkIfHref>
  );
}

TopicTitle.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
};
