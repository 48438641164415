import React from 'react';
import { SocialShareMenu } from 'components/SocialShareMenu';
import closeIcon from 'assets/icons/close-full-btn.svg';
import ShareIcon from 'assets/icons/share-icon-v2.svg';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './styles.themed.scss';

/**
 * Expandable list of social sharing icons as used in Chat-style live blog cards
 * @param {object} props
 * @param {string} props.socialUrl
 * @param {string} [props.primaryHeadline]
 * @param {string} [props.icid]
 * @param {string} [props.id] id used as an identifier for this particular
 * instance of the SocialShareIconList. Used to reference for aria-controls
 * attribute on the expand button.
 * @returns {React.ReactElement}
 */
const SocialShareIconList = ({
  socialUrl,
  primaryHeadline = '',
  icid = '',
  id = '',
}) => {
  const [showSocialShare, setShowSocialShare] = React.useState(false);

  const shareListId = id
    ? `share-list-${id}`
    : undefined;

  return (
    <div className="share-list-container df">
      <div
        className="share-list__wrapper"
        data-testid="social-share-icon-list"
        id={shareListId}
        aria-hidden={!showSocialShare}
        inert={!showSocialShare ? 'true' : undefined}
      >
        <div className={cx('share-list__icons', { showSocialShare })}>
          <SocialShareMenu
            url={socialUrl}
            headline={primaryHeadline}
            actions={{ pinterest: false, save: false }}
            icid={icid}
            trackingEventName="mbt_navbar_socialShare"
            pageRegion="blog-card"
            useAriaLabelHeadline
          />
        </div>
      </div>
      <button
        className="social-share-button"
        type="button"
        onClick={() => setShowSocialShare(!showSocialShare)}
        data-testid="chat-card-social-share-button"
        aria-expanded={showSocialShare}
        aria-label={`${showSocialShare ? 'close' : 'open'} social share icon list`}
        aria-controls={shareListId}
      >
        <span className={cx('share-btn-txt', { showSocialShare })}>Share</span>
        {showSocialShare ? (
          <img
            alt="close icon"
            src={closeIcon}
            height={28}
            width={28}
          />
        ) : (
          <>
            <img
              alt="social share icon trigger"
              src={ShareIcon}
              height={30}
              width={30}
            />
          </>
        )}

      </button>
    </div>
  );
};

SocialShareIconList.propTypes = {
  socialUrl: PropTypes.string.isRequired,
  primaryHeadline: PropTypes.string,
  icid: PropTypes.string,
  id: PropTypes.string,
};


export { SocialShareIconList };
